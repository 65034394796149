import React, {useState, useEffect} from 'react';
import { styled } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import Grid from '@mui/material/Grid';
import firebase from 'firebase/compat/app'
import {
    getStorage,
    ref as sRef,
    uploadBytesResumable,
    uploadBytes,
    getDownloadURL 
} from "firebase/storage";
import { ref, runTransaction, getDatabase, set , onValue , get, onChildAdded, onChildChanged, onChildRemoved  } from 'firebase/database'
import { doc, onSnapshot, collection, query, where } from "firebase/firestore";
import { realDB } from '../../../../util/initFirebase';
import 'firebase/database'
import 'firebase/storage'
import CardContent from '@mui/material/CardContent';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import { Link } from "react-router-dom";
import { FormControl, TextField } from '@mui/material';



const Item = styled(Paper)(({ theme }) => ({
  backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
  ...theme.typography.body2,
  padding: theme.spacing(1),
  textAlign: 'center',
  color: theme.palette.text.secondary,
}));


function Customers() {

  
  const [getAllEnuqueries, setgetAllEnuqueries] = useState([]);
  const [getAllEnqueriesSet, setgetAllEnqueriesSet] = useState([]);
  const getAllEnqueries = () => {
    const db = getDatabase();
    const enqueries = ref(db, 'user/query');
    onValue(enqueries, (snapshot) => {
        const data = snapshot.val();
        setgetAllEnuqueries(data);
        setgetAllEnqueriesSet(data);
        console.log(data)
      });
  }

  useEffect(() => {
      getAllEnqueries()
  }, [])
  

  const [isSuccess, setisSuccess] = useState(false);
  const enqueryToProcess = (e, enq) => {
    e.preventDefault(); 
    console.log('enq -> ',enq.id);
    
    const db = getDatabase();
    set(ref(db, `user/query/${enq.id}`), {
      id: enq.id,
      name: enq.name,
      phone: enq.phone,
      status: 'Process',
      productRequest: enq.productRequest
    }).then(res => {
      setisSuccess(true)
    })

  }

  const enqueryToHold = (e, enq) => {
    e.preventDefault(); 
    console.log('enq -> ',enq.id);
    
    const db = getDatabase();
    set(ref(db, `user/query/${enq.id}`), {
      id: enq.id,
      name: enq.name,
      phone: enq.phone,
      status: 'Hold',
      productRequest: enq.productRequest
    }).then(res => {
      setisSuccess(true)
    })

  }

  const backToNew = (e, enq) => {
    e.preventDefault();
    
    const db = getDatabase();
    set(ref(db, `user/query/${enq.id}`), {
      id: enq.id,
      name: enq.name,
      phone: enq.phone,
      enquery: enq.enquery, 
      status: 'New',
      productRequest: enq.productRequest
    }).then(res => {
      setisSuccess(true)
    })
  }

  const backToProcess = (e, enq) => {
    e.preventDefault();
    
    const db = getDatabase();
    set(ref(db, `user/query/${enq.id}`), {
      id: enq.id,
      name: enq.name,
      phone: enq.phone,
      enquery: enq.enquery, 
      status: 'Process',
      productRequest: enq.productRequest
    }).then(res => {
      setisSuccess(true)
    })
  }

  const enqueryToDone = (e, enq) => {
    e.preventDefault();
    const db = getDatabase();
    set(ref(db, `user/query/${enq.id}`), {
      id: enq.id,
      name: enq.name,
      phone: enq.phone,
      status: 'Done',
      productRequest: enq.productRequest
    }).then(res => {
      setisSuccess(true)
    })
  }


  const enqueryToDelete = (e, enq) => {
    e.preventDefault();
    const db = getDatabase();
    set(ref(db, `user/query/${enq.id}`), {
 
    }).then(res => {
      setisSuccess(true)
    })
  }

  const enqueryToNew = (e, enq) => {
    e.preventDefault();
    const db = getDatabase();
    set(ref(db, `user/query/${enq.id}`), {
      id: enq.id,
      name: enq.name,
      phone: enq.phone,
      status: 'New',
      productRequest: enq.productRequest
    }).then(res => {
      setisSuccess(true)
    })
  }


  const enqueryToRejected = (e, enq) => {
    e.preventDefault();
    const db = getDatabase();
    set(ref(db, `user/query/${enq.id}`), {
      id: enq.id,
      name: enq.name,
      phone: enq.phone,
      status: 'Rejected',
      productRequest: enq.productRequest
    }).then(res => {
      setisSuccess(true)
    })
  }

  const enqueryToRemove = (e, enq) => {
    e.preventDefault();
    const db = getDatabase();
    set(ref(db, `user/query/${enq.id}`), {
    
    }).then(res => {
      setisSuccess(true)
    })
  }

  const deleteEnquery = (e, enq) => {
    e.preventDefault();
    const db = getDatabase();
    set(ref(db, `user/query/${enq.id}`), {
    
    }).then(res => {
      setisSuccess(true)
    })
  }

  const redirectToViewProduct = (e, enq) => {
    e.preventDefault();
    
  }


  const [setsearchCustomers, setsetsearchCustomers] = useState('');
  const [searchPhone, setsearchPhone] = useState(0);


  const searchCustomers = (value) => {
        console.log("Value -> ", value);
        if(value === ""){
            setgetAllEnuqueries(getAllEnqueriesSet)
        }
        else if(getAllEnuqueries){
          const searchTemp = Object.entries(getAllEnuqueries).filter((enq) => {
                if(enq[1]){
                   return enq[1];
                }
                else if(enq[1].phone.includes(value)){
                   return enq[1];
                }
          });
          console.log("Searched Values -> ", searchTemp)
          setgetAllEnuqueries(searchTemp);
        }
  }


  

  return (
    <div>
        
        <div className='container' style={{
          backgroundColor:'#FAF7F0'
        }}>
          <FormControl fullWidth variant="outlined" style={{
              marginBottom:15,
              marginTop:25
          }}>
            <p>
              Search Customers By Id
            </p>
            <TextField onChange={(e) => searchCustomers(e.target.value)} id="outlined-basic" label="Search Customers" variant="outlined" />
            <Button onClick={(e) => searchCustomers(e)} variant="outlined" style={{
              marginTop:15
            }}>Search</Button>
            </FormControl>
          <div style={{display: "flex", justifyContent: "center", alignItems: "center", padding: "1.5rem 0"}}>
            <div>
            
            </div>
              <p style={{fontSize: "1rem"}}>
                All WhatsApp Enquery
              </p>
              </div>
              <div>
              <Box sx={{ flexGrow: 1 }}>
      <Grid container spacing={2}>
        <Grid item xs={4}>
          <Item>
            <h5>
              All New Enquries
            </h5>
            <div>
              {getAllEnuqueries && Object.entries(getAllEnuqueries).map((en, index) => {
                if(en[1].status === 'New')
                return(
                  <CardContent key={index} style={{
                    backgroundColor:'#2F6C00',
                    marginBottom:8,
                    
                  }}>
                    {
                      console.log('en -> ', en)
                    }
      <Typography sx={{ fontSize: 14 }} style={{color: "#fff"}} gutterBottom>
            Name: {en[1].name}
      </Typography>
      <Typography sx={{ fontSize: 14 }} style={{color: "#fff"}} gutterBottom>
            Phone: {en[1].phone}
      </Typography>
      {/* <Typography variant="h5" component="div">
        Phone
      </Typography> */}
      <Typography sx={{ mb: 1.5 }} style={{color: "#fff"}}>
        Enqueries:  
        <br>
        </br>
       
        <Link to={process.env.PUBLIC_URL + "/product/" + en[1].productRequest.id}>
        
      
      View Product 
      </Link>
      </Typography> 
      <Typography variant="body2" style={{color: "#fff"}}>
          {en[1].enquery}
        <br />
      </Typography>
      <Button onClick={(e) => enqueryToProcess(e,en[1])} variant="outlined" style={{color: "#fff", border: "2px solid #fff", marginTop: "1rem"}} size="small">
        On-Process    
      </Button>
      <Button onClick={(e) => enqueryToHold(e,en[1])} variant="outlined" style={{color: "#fff", border: "2px solid #fff", marginLeft: "1rem", marginTop: "1rem"}} size="small">
        To-Hold    
      </Button>
      <Button onClick={(e) => enqueryToDone(e,en[1])} variant="outlined" style={{color: "#fff", border: "2px solid #fff", marginLeft: "1rem", marginTop: "1rem"}} size="small">
        To-Done    
      </Button>
      
    </CardContent>
                )
              })
              }
            </div>
            <div>

            </div>
          </Item>
        </Grid>
        <Grid item xs={4}>
          <Item>
            <div>
<h5>
              All OnProcess Enquries
            </h5>
            <div>
              {getAllEnuqueries && Object.entries(getAllEnuqueries).map((en, index) => {
                if(en[1].status === 'Process')
                return(
                  <CardContent key={index} style={{
                    backgroundColor:'#554800',
                    marginBottom:8
                  }}>
                    {
                      console.log('en -> ', en)
                    }
      <Typography sx={{ fontSize: 14 }}  style={{color: "#fff"}} gutterBottom>
            Name: {en[1].name}
      </Typography>
      <Typography sx={{ fontSize: 14 }} style={{color: "#fff"}} gutterBottom>
            Phone: {en[1].phone}
      </Typography>
      {/* <Typography variant="h5" component="div">
        Phone
      </Typography> */}
      <Typography sx={{ mb: 1.5 }} style={{color: "#fff"}}>
        Enqueries:
      </Typography>
      <Link to={process.env.PUBLIC_URL + "/product/" + en[1].productRequest.id}>
        View Product 
        </Link>
      <Typography variant="body2" style={{color: "#fff"}}>
          {en[1].enquery}
        <br />
        
        {'"a benevolent smile"'}
      </Typography>
      <Button onClick={(e) => enqueryToNew(e,en[1])} variant="outlined" size="small" style={{color: "#fff", border: "2px solid #fff", marginTop: "1rem"}}>
        Back To New   
      </Button>
      <Button onClick={(e) => enqueryToDone(e,en[1])} variant="outlined" style={{color: "#fff", border: "2px solid #fff", marginLeft: "1rem", marginTop: "1rem"}} size="small">
        To-Done    
      </Button>
      
    </CardContent>
                )
              })
              }
            </div>
            </div>
          </Item>
        </Grid>
        <Grid item xs={4}>
          <Item>
          <h5>
              All Done Enquries
            </h5>
            <div>
              {getAllEnuqueries && Object.entries(getAllEnuqueries).map((en, index) => {
                if(en[1].status === 'Done')
                return(
                  <CardContent key={index} style={{
                    backgroundColor:'#43007E',
                    marginBottom:8
                  }}>
                    {
                      console.log('en -> ', en)
                    }
      <Typography sx={{ fontSize: 14 }}  style={{color: "#fff"}} gutterBottom>
            Name: {en[1].name}
      </Typography>
      <Typography sx={{ fontSize: 14 }}  style={{color: "#fff"}} gutterBottom>
            Phone: {en[1].phone}
      </Typography>
      {/* <Typography variant="h5" component="div">
        Phone
      </Typography> */}
      <Typography sx={{ mb: 1.5 }}  style={{color: "#fff"}}>
        Enqueries:
      </Typography>
      <Link to={process.env.PUBLIC_URL + "/product/" + en[1].productRequest.id}>
        View Product 
        </Link>

      <Typography variant="body2"  style={{color: "#fff"}}>
          {en[1].enquery}
        <br />
        {'"a benevolent smile"'}
      </Typography>
      <Button onClick={(e) => enqueryToProcess(e,en[1])} variant="outlined" style={{color: "#fff", border: "2px solid #fff", marginTop: "1rem"}} size="small">
        On-Process    
      </Button>
      <Button onClick={(e) => enqueryToRemove(e,en[1])} variant="outlined" style={{color: "#fff", border: "2px solid #fff", marginLeft: "1rem", marginTop: "1rem"}} size="small">
          Deal Done
      </Button>
      
    </CardContent>
                )
              })
              }
            </div>
          </Item>
        </Grid>
        <Grid item xs={4}>
          <Item>
            <h5>
              All Holded Deals
            </h5>
            <div>
              {getAllEnuqueries && Object.entries(getAllEnuqueries).map((en, index) => {
                if(en[1].status === 'Hold')
                return(
                  <CardContent key={index} style={{
                    backgroundColor:'#FF884B',
                    marginBottom:8
                  }}>
                    {
                      console.log('en -> ', en)
                    }
      <Typography sx={{ fontSize: 14 }}  style={{color: "#fff"}} gutterBottom>
            Name: {en[1].name}
      </Typography>
      <Typography sx={{ fontSize: 14 }}  style={{color: "#fff"}} gutterBottom>
            Phone: {en[1].phone}
      </Typography>
      {/* <Typography variant="h5" component="div">
        Phone
      </Typography> */}
      <Typography sx={{ mb: 1.5 }}  style={{color: "#fff"}}>
        Enqueries:
      </Typography>
      <Typography variant="body2"  style={{color: "#fff"}}>
          {en[1].enquery}
        <br />
        {'"a benevolent smile"'}
      </Typography>
      <Button onClick={(e) => deleteEnquery(e,en[1])} variant="outlined" style={{color: "#fff", border: "2px solid #fff", marginTop: "1rem"}} size="small">
        Delete
      </Button>
      <Button onClick={(e) => enqueryToRejected(e,en[1])} variant="outlined" style={{color: "#fff", border: "2px solid #fff", marginLeft: "1rem", marginTop: "1rem"}} size="small">
        Rejected
      </Button>
      
    </CardContent>
                )
              })
              }
            </div>
          </Item>
        </Grid>
        <Grid item xs={8}>
          <Item>All Rejected Deals

          <div>
              {getAllEnuqueries && Object.entries(getAllEnuqueries).map((en, index) => {
                if(en[1].status === 'Rejected')
                return(
                  <CardContent key={index} style={{
                    backgroundColor:'#CC0A0A',
                    marginBottom:8
                  }}>
                    {
                      console.log('en -> ', en)
                    }
      <Typography sx={{ fontSize: 14 }}  style={{color: "#fff"}} gutterBottom>
            Name: {en[1].name}
      </Typography>
      <Typography sx={{ fontSize: 14 }}  style={{color: "#fff"}} gutterBottom>
            Phone: {en[1].phone}
      </Typography>
      {/* <Typography variant="h5" component="div">
        Phone
      </Typography> */}
      <Typography sx={{ mb: 1.5 }}  style={{color: "#fff"}}>
        Enqueries:
      </Typography>
      <Typography variant="body2"  style={{color: "#fff"}}>
          {en[1].enquery}
        <br />
        {'"a benevolent smile"'}
      </Typography>
      <Button onClick={(e) => deleteEnquery(e,en[1])} variant="outlined" style={{color: "#fff", border: "2px solid #fff", marginTop: "1rem"}} size="small">
        Delete
      </Button>
    </CardContent>
                )
              })
              }
            </div>
          </Item>
        </Grid>
      </Grid>
    </Box>
              </div>
        </div>
    </div>
  )
}

export default Customers