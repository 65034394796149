import React, { useState, useEffect }  from 'react';
import Col from 'react-bootstrap/Col';
import Nav from 'react-bootstrap/Nav';
import Row from 'react-bootstrap/Row';
import Tab from 'react-bootstrap/Tab';
import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import Alert from 'react-bootstrap/Alert';
import firebase from 'firebase/compat/app'
import {
    getStorage,
    ref as sRef,
    uploadBytesResumable,
    uploadBytes,
    getDownloadURL 
} from "firebase/storage";
import { ref, runTransaction, getDatabase, set , onValue , get, onChildAdded, onChildChanged, onChildRemoved  } from 'firebase/database'
import { doc, onSnapshot, collection, query, where } from "firebase/firestore";
import { realDB } from '../../../../util/initFirebase';
import 'firebase/database'
import 'firebase/storage'
import { v4 as uuidv4 } from 'uuid';
import { TextareaAutosize } from '@mui/base';
import FileUpload from "react-mui-fileuploader"


function Products() {

    const [category, setCategory] = useState('');


    const [productName, setproductName] = useState('');
    const [productCategory, setproductCategory] = useState('');
    const [productPrice, setproductPrice] = useState('');
    const [productDiscPrice, setproductDiscPrice] = useState('');
    const [productAge, setproductAge] = useState('');
    const [productCycleType, setproductCycleType] = useState('');
    const [productCycleWheels, setproductCycleWheels] = useState('');
    const [productCycleWheelRim, setproductCycleWheelRim] = useState('');
    const [productCycleWheelSpokes, setproductCycleWheelSpokes] = useState('');
    const [productCycleWheelTyre, setproductCycleWheelTyre] = useState('');
    const [productCycleStickersType, setproductCycleStickersType] = useState('');
    const [productDescription, setproductDescription] = useState('');
    const [productBrand, setproductBrand] = useState('');
    const [productMainImage, setproductMainImage] = useState('');

    // ** Product Frame With Size
    const [productFrameAndPrice, setproductFrameAndPrice] = useState([]);
    const [productFrameSelectedSize, setproductFrameSelectedSize] = useState('');
    const [productFrameselectedPrice, setproductFrameselectedPrice] = useState('');
    const [productFrameselectedDisPrice, setproductFrameselectedDisPrice] = useState('');

    
    const [productColor, setproductColor] = useState('');
    const [subImgOne, setsubImgOne] = useState('');
    const [subImgTwo, setsubImgTwo] = useState('');
    const [subImgThree, setsubImgThree] = useState('');
    const [subImgFour, setsubImgFour] = useState('');
    const [productFrame, setproductFrame] = useState('');
    const [productMaterial, setproductMaterial] = useState('');
    const [productOverview, setproductOverview] = useState('');
    const [productTopSpeed, setproductTopSpeed] = useState(''); 
    const [productTopRange, setproductTopRange] = useState('');
    const [productSize, setproductSize] = useState('');

    // ** Components

    const [productBrakeSystem, setproductBrakeSystem] = useState('');
    const [productHandleBar, setproductHandleBar] = useState('');
    const [productBBSet, setproductBBSet] = useState('');
    const [productCables, setproductCables] = useState('');

    // **

    const [productDriveTrain, setproductDriveTrain] = useState('');
    const [productGender, setproductGender] = useState('');
    const [productHeight, setproductHeight] = useState('');
    const [productRatings, setproductRatings] = useState('');
    const [productCyclePackaging, setproductCyclePackaging] = useState('');
    const [productFav, setproductFav] = useState('');
    const [productisAvail, setproductisAvail] = useState(false);
    const [productisStock, setproductisStock] = useState(false);
    const [productisSoldOut, setproductisSoldOut] = useState(false);
    const [productisOffer, setproductisOffer] = useState(false);


    // ** 

    const [productCycleComponents, setproductCycleComponents] = useState('');

    // ** 


    const [productBrakeSystel, setproductBrakeSystel] = useState('');
    const [productSaddle, setproductSaddle] = useState('');
    const [productHandle, setproductHandle] = useState('');
    const [productGrip, setproductGrip] = useState('');
    const [productBrakeLevel, setproductBrakeLevel] = useState('');
    const [productShifter, setproductShifter] = useState('');
    const [productCableHousing, setproductCableHousing] = useState('');

    // **


    const [isAvail, setisAvail] = useState(false);
    const [isStock, setisStock] = useState(false);
    const [isSoldout, setisSoldout] = useState(false);
    const [isOffers, setisOffers] = useState(false);
    const [isNew, setisNew] = useState(false);


  const handleChange = (event) => {
    setproductCategory(event.target.value);
  };


  const handleBranch = (event) => {
    setproductBrand(event.target.value);
  };

  const handleCycleFrame = (event) => {
    setproductFrame(event.target.value)
  }

  const handleCycleType = (event) => {
    setproductCycleType(event.target.value);
  };

  const handleCycleHeight = (event) => {
    setproductHeight(event.target.value);
  };

  const successAlert = () => {
    if(isSuccess)
    return(
      <Alert key={'success'} variant={'success'}>
        Your Upload is Successful
    </Alert>
    )
  };

  const [isSuccess, setisSuccess] = useState(false);
  useEffect(() => {
      if(isSuccess){
        setTimeout(() => {
          setisSuccess(false)
      }, 3000);
    }
    getAllCategories()
  }, [isSuccess])
  

  const submitCategory = (e) => {
    e.preventDefault();
    const id = uuidv4();
    const db = getDatabase();
      set(ref(db, `category/${id}`), {
        id: id,
        category: category
      }).then(res => {
        setCategory('')
        setisSuccess(true)
      })

  }

  const submitProduct = (e) => {
    e.preventDefault();
    const id = uuidv4();
    const db = getDatabase();
    set(ref(db, `product/${id}`), {
      id: id,
      name: productName,
      category: productCategory,
      age: productAge,
      price: productPrice,
      disPrice: productDiscPrice,
      gender: productGender,
      rating: productRatings,
      desciption: productDescription,
      brand: productBrand,
      images:[productMainImage, subImgOne, subImgTwo, subImgThree, subImgFour],
      priceSize: productFrameAndPrice,
      rimType: productCycleWheelRim,
      spokeType: productCycleWheelSpokes,
      stickersType: productCycleStickersType,
      packingAssembly: productCyclePackaging,
      overview: productOverview,
      components: productCycleComponents,
      mainImg: productMainImage,
      subImgOne:  subImgOne,
      subImgTwo:  subImgTwo,
      subImgThree: subImgThree,
      drivetrain: productDriveTrain,
      frame: productFrame,
      height: productHeight,
      material: productMaterial,
      topSpeed: productTopSpeed,
      topRanger: productTopRange,
      favroite: true,
      isAvail: productisAvail
      

    }).then(res => {
      setisSuccess(true)
      setproductName('')
setproductCategory('')
setproductPrice('')
setproductDiscPrice('')
setproductAge('')
setproductCycleType('')
setproductCycleWheelRim('')
setproductCycleWheelSpokes('')
setproductCycleStickersType('')
setproductDescription('')
setproductBrand('')
setproductMainImage('')
setsubImgOne('')
setsubImgTwo('')
setsubImgThree('')
setproductFrame('')
setproductMaterial('')
setproductOverview('')
setproductTopSpeed('')
setproductFrameAndPrice([]);
setproductSize('')
setproductDriveTrain('')
setproductGender('')
setproductHeight('')
setproductRatings('')
setproductCyclePackaging('')
setproductCycleComponents('')
      console.log('res -> ', res)
    }).catch(err => {
      console.log(err)
    })
  }


  const [productImage, setproductImage] = useState('');
  const [productImageURL, setproductImageURL] = useState('');
  const [progressing, setprogressing] = useState(false);
  const [imageUploadMainDone, setimageUploadMainDone] = useState(false);


  const [productSecondImage, setproductSecondImage] = useState('');
  const [productImageMainURL, setproductImageMainURL] = useState('');
  const [progressingMain, setprogressingMain] = useState(false);
  const [imageUploadSecondDone, setimageUploadSecondDone] = useState(false);

  const productImageHandle = (e) =>{
    setproductImage(e.target.files[0])
  }

  const uploadProductMain = (e) => {

          e.preventDefault(); // prevent page refreshing

          let file = productImage;
          var storage = firebase.storage();
          var storageRef = storage.ref();
          var uploadTask = storageRef.child(`product/image/${uuidv4()}/${file.name}`).put(file);
        
          uploadTask.on(firebase.storage.TaskEvent.STATE_CHANGED,
            (snapshot) =>{
              var progress = Math.round((snapshot.bytesTransferred/snapshot.totalBytes))*100
              setprogressing(true)
            },(error) =>{
              throw error
            },() =>{
              // uploadTask.snapshot.ref.getDownloadURL().then((downloadURL) =>{
        
              uploadTask.snapshot.ref.getDownloadURL().then((url) =>{
                setproductImageURL(url)
                setprogressing(false)

              })
  }
)}

  const getAllProducts = () => {

  }


  const [allCategories, setallCategories] = useState([]);
  
  const getAllCategories = () => {
    const db = getDatabase();
    const categories = ref(db, 'category/');
    onValue(categories, (snapshot) => {
        const data = snapshot.val();
        setallCategories(data);
      });
}

  const [allProductsList, setallProductsList] = useState([]);
  const getAllProductsHere = () => {
    const db = getDatabase();
    const products = ref(db, 'product/');
    onValue(products, (snapshot) => {
        const data = snapshot.val();
        setallProductsList(data);
      });
  }

  useEffect(() => {
    getAllProductsHere()
  }, [])
  


const handleFileUploadError = (error) => {
  // Do something...
}

const handleFilesChange = (files) => {
  // Do something...
}

const deleteProduct = (e, id) => {
  e.preventDefault();

  const db = getDatabase();
    set(ref(db, `product/${id}`), {
    
    }).then(res => {
      
    })

}

const [theProduct, settheProduct] = useState('');
const [theProductEditId, settheProductEditId] = useState('');
const [editButtonStatus, seteditButtonStatus] = useState(false);
const editProduct = (e, id) => {
    seteditButtonStatus(true);
    e.preventDefault();
    console.log('Product Edit -> ', id);
    const db = getDatabase();
    const thatProducts = ref(db, `product/${id}`);
    onValue(thatProducts, (snapshot) => {
        const data = snapshot.val();
        console.log("THE DATA => ",data);
        console.log(data.price);
        console.log(data.age);
        console.log(data);
        settheProductEditId(id);
        console.log("Data -> ", data);
        setproductName(data.name);
        setproductBrand(data.brand);
        setproductFrame(data.frame);
        setproductGender(data.gender);
        setproductCategory(data.category);
        setproductMaterial(data.material);
        setproductAge(data.age);
        setproductPrice(data.price);
        setproductDiscPrice(data.disPrice);
        setproductFrameAndPrice(data.priceSize);
        setproductDescription(data.desciption);
        setproductOverview(data.overview);
        setproductTopSpeed(data.topSpeed);
        setproductRatings(data.rating);
        setproductFrame(data.frame);
        setproductMainImage(data.mainImg);
        setsubImgOne(data.subImgOne);
        setsubImgTwo(data.subImgTwo);
        setsubImgThree(data.subImgThree);
        setproductDriveTrain(data.drivetrain);
        setproductHeight(data.height);
        setproductCycleWheelRim(data.rimType);
        setproductCycleWheelSpokes(data.spokeType);
        setproductCycleStickersType(data.stickersType);
        setproductCyclePackaging(data.packingAssembly);
      });
}


const submitEditProduct = (e) => {
  e.preventDefault();

  const db = getDatabase();
    set(ref(db, `product/${theProductEditId}`), {
      id: theProductEditId,
      name: productName,
      category: productCategory,
      age: productAge,
      price: productPrice,
      disPrice: productDiscPrice,
      gender: productGender,
      rating: productRatings,
      desciption: productDescription,
      brand: productBrand,
      images:[productMainImage, subImgOne, subImgTwo, subImgThree, subImgFour],
      priceSize: productFrameAndPrice,
      rimType: productCycleWheelRim,
      spokeType: productCycleWheelSpokes,
      stickersType: productCycleStickersType,
      packingAssembly: productCyclePackaging,
      overview: productOverview,
      components: productCycleComponents,
      mainImg: productMainImage,
      subImgOne:  subImgOne,
      subImgTwo:  subImgTwo,
      subImgThree: subImgThree,
      drivetrain: productDriveTrain,
      frame: productFrame,
      height: productHeight,
      material: productMaterial,
      topSpeed: productTopSpeed,
      topRanger: productTopRange,
      favroite: true,
      isAvail: productisAvail
      
    }).then(res => {
      setproductName('')
      setproductCategory('')
      setproductPrice('')
      setproductDiscPrice('')
      setproductAge('')
      setproductCycleType('')
      setproductCycleWheelRim('')
      setproductCycleWheelSpokes('')
      setproductCycleStickersType('')
      setproductDescription('')
      setproductBrand('')
      setproductMainImage('')
      setsubImgOne('')
      setsubImgTwo('')
      setsubImgThree('')
      setproductFrame('')
      setproductMaterial('')
      setproductOverview('')
      setproductTopSpeed('')
      setproductSize('')
      setproductDriveTrain('')
      setproductGender('')
      setproductHeight('')
      setproductRatings('')
      setproductCyclePackaging('')
      setproductCycleComponents('')
    })

}

const getAllSearchItems = (searchTxt) => {

      let searchProduct = allProductsList;
      let searchedProduct = Object.entries(searchProduct).toLowerCase().includes(searchTxt.toLowerCase());
      setallProductsList(searchedProduct)
}



const addFrameWithSize = (e) => {

    e.preventDefault(); 
    let frameList = [...productFrameAndPrice];
    let frameObject = {
      size: productFrameSelectedSize,
      price: productFrameselectedPrice,
      disPice: productFrameselectedDisPrice
    }
    frameList.push(frameObject);
    setproductFrameAndPrice(frameList);

} 



  return (
    <div>
      {
        successAlert()
      }
        
        <div className='container'>
        <Tab.Container id="left-tabs-example" defaultActiveKey="first">
      <Row>
        <Col sm={3}>
          <Nav variant="pills" className="flex-column">
            <Nav.Item>
              <Nav.Link eventKey="first">Catgory</Nav.Link>
            </Nav.Item>
            <Nav.Item>
              <Nav.Link eventKey="second">Product</Nav.Link>
            </Nav.Item>
            <Nav.Item>
              <Nav.Link eventKey="third">Manage</Nav.Link>
            </Nav.Item>
          </Nav>
        </Col>
        <Col sm={9}>
          <Tab.Content>
            <Tab.Pane eventKey="first">
              <div>
              <Form.Label htmlFor="inputPassword5">Category</Form.Label>
              <Form.Control
                type="text"
                value={category}
                placeholder='Enter the Category'
                onChange={(e) => setCategory(e.target.value)}
            />
            <Button variant="primary" onClick={(e) => submitCategory(e)} className='mt-2'>Create</Button>
              </div>
            </Tab.Pane>
            <Tab.Pane eventKey="second">
              <div>
              <Form.Label htmlFor="inputPassword5">Name</Form.Label>
              <Form.Control
                type="text"
                placeholder='Enter Product Name'
                value={productName}
                onChange={(e) => setproductName(e.target.value)}
                />
                 <FormControl style={{marginTop: "25px"}} sx={{ m: 1, minWidth: 220 }} size="small">
      
                 <Form.Label htmlFor="inputPassword5">Select the Brand</Form.Label>
      
          <Select
              labelId="demo-select-small"
              id="demo-select-small"
              value={productBrand}
              label="Branch"
              onChange={handleBranch}
          >
            <MenuItem value={'Hero'}>Hero</MenuItem>
            <MenuItem value={'Atlas'}>Atlas</MenuItem>
            <MenuItem value={'Avon'}>Avon</MenuItem>
            <MenuItem value={'Protrek Bikes'}>Protrek Bikes</MenuItem>
            <MenuItem value={'Geekay'}>Geekay</MenuItem>
            <MenuItem value={'Hercules'}>Hercules</MenuItem>
            <MenuItem value={'Mach City'}>Mach City</MenuItem>
            <MenuItem value={'Ninety One'}>Ninety One</MenuItem>
            <MenuItem value={'BSA'}>BSA</MenuItem>
            <MenuItem value={'Firefox'}>Firefox</MenuItem>
     
            </Select>
            </FormControl>
                 <FormControl style={{marginTop: "25px"}} sx={{ m: 1, minWidth: 220 }} size="small">
      
                 <Form.Label htmlFor="inputPassword5">Select the Frame</Form.Label>
      
          <Select
              labelId="demo-select-small"
              id="demo-select-small"
              value={productFrame}
              label="Branch"
              onChange={handleCycleFrame}
          >
            <MenuItem value={'Aluminum'}>Aluminum</MenuItem>
            <MenuItem value={'Carbon Fiber'}>Carbon Fiber</MenuItem>
            <MenuItem value={'Titanium'}>Titanium</MenuItem>
            <MenuItem value={'Steel'}>Steel</MenuItem>
            <MenuItem value={'Alloy'}>Alloy</MenuItem>
            <MenuItem value={'Bamboo + More'}>Bamboo + More</MenuItem>
     
            </Select>
            </FormControl>
                 <FormControl style={{marginTop: "25px"}} sx={{ m: 1, minWidth: 220 }} size="small">
      
                 <Form.Label htmlFor="inputPassword5">Select the Gender</Form.Label>
      
          <Select
              labelId="demo-select-small"
              id="demo-select-small"
              value={productGender}
              label="Branch"
              onChange={(e) => setproductGender(e.target.value)}
          >
            <MenuItem value={'Aluminum'}>Unisex</MenuItem>
            <MenuItem value={'Him Cycle'}>Him Cycle</MenuItem>
            <MenuItem value={'Her Cycle'}>Her Cycle</MenuItem>
     
            </Select>
            </FormControl>
                 {/* <FormControl style={{marginTop: "25px"}} sx={{ m: 1, minWidth: 220 }} size="small">
      
                 <Form.Label htmlFor="inputPassword5">Select the Brand</Form.Label>
      
<Select
    labelId="demo-select-small"
    id="demo-select-small"
    value={productCycleType}
    label="Branch"
    onChange={handleCycleType}
>
            <MenuItem value={'Brand One'}>Brand One</MenuItem>
            <MenuItem value={'Brand Two'}>Brand Two</MenuItem>
            <MenuItem value={'Brand Three'}>Brand Three</MenuItem>
            <MenuItem value={'Brand Four'}>Brand Four</MenuItem>
            <MenuItem value={'Brand Five'}>Brand Five</MenuItem>
            <MenuItem value={'Brand Six'}>Brand Six</MenuItem>
     
</Select>
</FormControl> */}
                 <FormControl style={{marginTop: "25px"}} sx={{ m: 1, minWidth: 220 }} size="small">
              <Form.Label htmlFor="inputPassword5">Select the Category</Form.Label>

                <Select
                    labelId="demo-select-small"
                    id="demo-select-small"
                    value={productCategory}
                    label="Category"
                    onChange={handleChange}
                >
                    <MenuItem value="">
                    <em>None</em>
                    </MenuItem>
                    {allCategories && Object.entries(allCategories).map((cate, index) => {
                        return(
                            <MenuItem value={cate[1].category}>{cate[1].category}</MenuItem>
                        )
                    })
                    }
                </Select>
                </FormControl>
                 <FormControl style={{marginTop: "25px"}} sx={{ m: 1, minWidth: 220 }} size="small">
              <Form.Label htmlFor="inputPassword5">Select the Material</Form.Label>

                <Select
                    labelId="demo-select-small"
                    id="demo-select-small"
                    value={productMaterial}
                    label="Material"
                    onChange={(e) => setproductMaterial(e.target.value)}
                >
                    <MenuItem value="">
                    <em>None</em>
                    </MenuItem>
                            <MenuItem value={'Steel'}>Steel</MenuItem>
                            <MenuItem value={'Alloy'}>Alloy</MenuItem>
                    
                </Select>
                </FormControl>
                 <FormControl style={{marginTop: "25px"}} sx={{ m: 1, minWidth: 220 }} size="small">
              <Form.Label htmlFor="inputPassword5">Select the Age</Form.Label>

                <Select
                    labelId="demo-select-small"
                    id="demo-select-small"
                    value={productAge}
                    label="Material"
                    onChange={(e) => setproductAge(e.target.value)}
                >
                    <MenuItem value="">
                    <em>None</em>
                    </MenuItem>
                            <MenuItem value={'12+'}>12+</MenuItem>
                            <MenuItem value={'15 & Above'}>15 & Above</MenuItem>
                    
                </Select>
                </FormControl>
                <InputLabel id="demo-select-small"></InputLabel>

              <Form.Label htmlFor="inputPassword5">Enter the Price</Form.Label>
                
              <Form.Control
                type="text"
                placeholder='Enter product price'
                value={productPrice}
                onChange={(e) => setproductPrice(e.target.value)}
                />
              <Form.Label htmlFor="inputPassword5">Enter the Discount Price</Form.Label>
              <Form.Control
                type="text"
                placeholder='Enter product price'
                value={productDiscPrice}
                onChange={(e) => setproductDiscPrice(e.target.value)}
                />
              <Form.Label htmlFor="inputPassword5" className='mt-3'>Enter the Description</Form.Label>
                
              <TextareaAutosize
                type="textarea"
                minRows={5}
                placeholder='Enter product description'
                value={productDescription}
                onChange={(e) => setproductDescription(e.target.value)}
                />
              <Form.Label htmlFor="inputPassword5" className='mt-3'>Enter the Overview</Form.Label>
                
              <TextareaAutosize
                type="textarea"
                minRows={5}
                placeholder='Enter product Overview'
                value={productOverview}
                onChange={(e) => setproductOverview(e.target.value)}
                />
             
                 <FormControl style={{marginTop: "25px"}} sx={{ m: 1, minWidth: 220 }} size="small">
                 <Form.Label htmlFor="inputPassword5" className='mt-3'>Enter the Top Speed</Form.Label>
                
                <Form.Control
                  type="text"
                  placeholder='Enter Top Speed'
                  value={productTopSpeed}
                  onChange={(e) => setproductTopSpeed(e.target.value)}
                  />
                  </FormControl>
               
                 <FormControl style={{marginTop: "25px"}} sx={{ m: 1, minWidth: 220 }} size="small">

                {/* Part  */}

                <FormControl style={{marginTop: "25px"}} sx={{ m: 1, minWidth: 220 }} size="small">
              <Form.Label htmlFor="inputPassword5">Select the Ratings</Form.Label>

                <Select
                    labelId="demo-select-small"
                    id="demo-select-small"
                    value={productRatings}
                    label="Material"
                    onChange={(e) => setproductRatings(e.target.value)}
                >
                    <MenuItem value="">
                    <em>None</em>
                    </MenuItem>
                            <MenuItem value={'5'}>5</MenuItem>
                            <MenuItem value={'4'}>4</MenuItem>
                            <MenuItem value={'3'}>3</MenuItem>
                            <MenuItem value={'2'}>2</MenuItem>
                            <MenuItem value={'1'}>1</MenuItem>
                    
                </Select>
                </FormControl>

                {/* Part  */}
               
                    </FormControl>
                    
                 <FormControl style={{marginTop: "25px"}} sx={{ m: 1, minWidth: 220 }} size="small">
                 <Form.Label htmlFor="inputPassword5" className='mt-3'>Select the Frame Size</Form.Label>
                 
              <Select
                  labelId="demo-select-small"
                  id="demo-select-small"
                  value={productSize}
                  label="Branch"
                  onChange={(e) => setproductSize(e.target.value)}
              >
            <MenuItem value={"152cm / 5'.071cm / 28"}>152cm / 5'.071cm / 28"</MenuItem>
            <MenuItem value={'Frame Size Two'}>160cm / 5'.3"/ 75cm / 29.5"</MenuItem>
            <MenuItem value={"170cm / 5'.7 79cm / 31"}>170cm / 5'.7" 79cm / 31"</MenuItem>
            
     
</Select>                      
                  </FormControl>
                  <div
                  style={{
                    margin:50
                  }}
                  >
 <div
                    style={{
                      marginTop:25
                    }}
                    >
                <Button variant="primary" className='mt-2 mb-3' onClick={(e) => {
      e.preventDefault();
      window.open("https://image-hosting-rudra.netlify.app/", "_blank")
      }}>Image Hosting</Button>

                          

                    </div>

                  </div>
                  <FormControl fullWidth>
                 <Form.Label className='mt-3'>Paste the Main Image URL</Form.Label>
                 <Form.Control
                  type="text"
                  placeholder='Enter product Ratings'
                  value={productMainImage}
                  onChange={(e) => setproductMainImage(e.target.value)}
                  />
                  </FormControl>
             
                  <FormControl fullWidth>
                 <Form.Label className='mt-3'>Paste the Sub One Image URL</Form.Label>
                 <Form.Control
                  type="text"
                  placeholder='Enter Sub Image 1 URL'
                  value={subImgOne}
                  onChange={(e) => setsubImgOne(e.target.value)}
                  />
                  </FormControl>
                  <FormControl fullWidth>
                 <Form.Label className='mt-3'>Paste the Sub Two Image URL</Form.Label>
                 <Form.Control
                  type="text"
                  placeholder='Enter Sub Image 2 URL'
                  value={subImgTwo}
                  onChange={(e) => setsubImgTwo(e.target.value)}
                  />
                  </FormControl>
                  <FormControl fullWidth>
                 <Form.Label className='mt-3'>Paste the Sub Three Image URL</Form.Label>
                 <Form.Control
                  type="text"
                  placeholder='Enter Sub Image 3 URL'
                  value={subImgThree}
                  onChange={(e) => setsubImgThree(e.target.value)}
                  />
                  </FormControl>
                  <FormControl fullWidth>
                 <Form.Label className='mt-3'>Paste the Sub Four Image URL</Form.Label>
                 <Form.Control
                  type="text"
                  placeholder='Enter Sub Image 4 URL'
                  value={subImgFour}
                  onChange={(e) => setsubImgFour(e.target.value)}
                  />
                  </FormControl>
                

                    <FormControl fullWidth style={{
                      marginTop:50
                    }}>
                      <h4 style={{
                    marginTop:120
                  }}>
                    Price For Size
                  </h4>
                  <div>
                    {productFrameAndPrice.map((frame, index) => {
                      return(
                        <div>
                          {
                            console.log('**************************** frame -> ', frame)
                          }
                          <p>
                              {
                                frame.size
                              }
                          </p>
                          <p>
                              {
                                frame.price
                              }
                          </p>
                          <p>
                              {
                                frame.disPice
                              }
                          </p>
                        </div>
                      )
                    }) 
                    }
                  </div>
                    <Form.Label htmlFor="inputPassword5">Enter the Price for Size</Form.Label>
                    <Select
                  labelId="demo-select-small"
                  id="demo-select-small"
                  value={productFrameSelectedSize}
                  label="Branch"
                  onChange={(e) => setproductFrameSelectedSize(e.target.value)}
                  style={{
                    marginBottom: 15
                  }}
              >
            <MenuItem value={"Ideal Age (2 Years - 4 Years) -  12T"}>Ideal Age (2 Years - 4 Years) -  12T</MenuItem>
            <MenuItem value={"Ideal Age (3 Years - 5 Years) - 14T"}>Ideal Age (3 Years - 5 Years) - 14T</MenuItem>
            <MenuItem value={"Ideal Age (4 Years - 6 Years) - 16T"}>Ideal Age (4 Years - 6 Years) - 16T</MenuItem>
            <MenuItem value={"Ideal Age (5 Years - 8 Years) - 20T"}>Ideal Age (5 Years - 8 Years) - 20T</MenuItem>
            <MenuItem value={"Ideal Age (9 Years - 12 Years) - 24T"}>Ideal Age (9 Years - 12 Years) - 24T</MenuItem>
            <MenuItem value={"Ideal Height (5' to 5'.7\") - 26T"}>Ideal Height (5' to 5'.5\") - 26T</MenuItem>
            <MenuItem value={"Ideal Height (5.7' to 6\") - 27.5T"}>Ideal Height (5.7' to 6\")  - 27.5T</MenuItem>
            <MenuItem value={"Ideal Height (6\" and Above) - 29T"}>Ideal Height (6\" and Above")  - 29T</MenuItem>
           
     
</Select>                 
                <Form.Control
                  type="text"
                  placeholder='Enter product price'
                  value={productFrameselectedPrice}
                  onChange={(e) => setproductFrameselectedPrice(e.target.value)}
                  />
                <Form.Control
                  type="text"
                  placeholder='Enter discount product price'
                  value={productFrameselectedDisPrice}
                  onChange={(e) => setproductFrameselectedDisPrice(e.target.value)}
                  />
                    </FormControl>
                <Button variant="primary" className='mt-2 mb-3' onClick={addFrameWithSize}>Add</Button>


                   

                  <h4 style={{
                    marginTop:120
                  }}>
                    Features
                  </h4>
                 {/* <FormControl style={{marginTop: "5px"}} sx={{ m: 1, minWidth: 220 }} size="small">

                <Form.Label htmlFor="inputPassword5" className='mt-3'>Enter the Frames</Form.Label>

                <Form.Control
                type="text"
                placeholder='Enter product Overview'
                value={productOverview}
                onChange={(e) => setproductOverview(e.target.value)}
                />
                </FormControl> */}
            

            <FormControl style={{marginTop: "5px"}} sx={{ m: 1, minWidth: 220 }} size="small">
<Form.Label htmlFor="inputPassword5" className='mt-3'>Enter the Drive Type</Form.Label>
              
<Select
    labelId="demo-select-small"
    id="demo-select-small"
    value={productDriveTrain}
    label="Branch"
    onChange={(e) => setproductDriveTrain(e.target.value)}
    placeholder="Select the Cycle Type"
>
            <MenuItem value={''}></MenuItem>
            <MenuItem value={'Sport'}>Sport</MenuItem>
            <MenuItem value={'Gym & Excercise'}>Gym & Excercise</MenuItem>
            <MenuItem value={'Track'}>Track</MenuItem>
            <MenuItem value={'City Cruze'}>City Cruze</MenuItem>
            <MenuItem value={'Lazy'}>Lazy</MenuItem>
     
</Select>
</FormControl>
            <FormControl style={{marginTop: "5px"}} sx={{ m: 1, minWidth: 220 }} size="small">
<Form.Label htmlFor="inputPassword5" className='mt-3'>Select the Wheels Type</Form.Label>
              
<Select
    labelId="demo-select-small"
    id="demo-select-small"
    value={productDriveTrain}
    label="Branch"
    onChange={(e) => setproductDriveTrain(e.target.value)}
    placeholder="Select the Cycle Type"
>
            <MenuItem value={''}></MenuItem>
            <MenuItem value={'Sport'}>Sport</MenuItem>
            <MenuItem value={'Gym & Excercise'}>Gym & Excercise</MenuItem>
            <MenuItem value={'Track'}>Track</MenuItem>
            <MenuItem value={'City Cruze'}>City Cruze</MenuItem>
            <MenuItem value={'Lazy'}>Lazy</MenuItem>
     
</Select>
</FormControl>

            <FormControl style={{marginTop: "5px"}} sx={{ m: 1, minWidth: 220 }} size="small">
<Form.Label htmlFor="inputPassword5" className='mt-3'>Select Height</Form.Label>
              
<Select
    labelId="demo-select-small"
    id="demo-select-small"
    value={productHeight}
    label="Height"
    onChange={handleCycleHeight}
    placeholder="Select the Component"
>
            <MenuItem value={"4'5 To 5"}>"4'5 To 5"</MenuItem>
            <MenuItem value={"5'7 To 6"}>"5'7 To 6"</MenuItem>
            <MenuItem value={"5' To 5'7"}>"5' To 5'7"</MenuItem>
            <MenuItem value={"6' & Above"}>"6' & Above"</MenuItem>
          
     
</Select>
</FormControl>

<h4 style={{
  marginTop:15
}}>
  Wheel - Spoke and Rim Type
</h4>
              <div>
              <FormControl style={{marginTop: "5px"}} sx={{ m: 1, minWidth: 220 }} size="small">
<Form.Label htmlFor="inputPassword5" className='mt-3'>Select Rim Type</Form.Label>
              
<Select
    labelId="demo-select-small"
    id="demo-select-small"
    value={productCycleWheelRim}
    label="Rim"
    onChange={(e) => setproductCycleWheelRim(e.target.value)}
    placeholder="Select the Component"
>
            <MenuItem value={"Double-wall Alloy Rim 14G X 36H"}>Double-wall Alloy Rim 14G X 36H</MenuItem>
            <MenuItem value={"Aerodynamic Steel MTB Rim"}>Aerodynamic Steel MTB Rim</MenuItem>
            <MenuItem value={"Heavy Duty Steel Rim"}>Heavy Duty Steel Rim</MenuItem>
            <MenuItem value={"Steel Rim"}>Steel Rim</MenuItem>
            <MenuItem value={"Single Valve Alloy Rim"}>Single Valve Alloy Rim</MenuItem>
            <MenuItem value={"Double Valve Alloy Wheel"}>Double Valve Alloy Wheel</MenuItem>
            <MenuItem value={"Triple Valve Alloy Wheel"}>Triple Valve Alloy Wheel</MenuItem>
            <MenuItem value={"Aerodynamic Heavy Duty Steel Rim"}>Aerodynamic Heavy Duty Steel Rim</MenuItem>
          
     
</Select>
</FormControl>
              <FormControl style={{marginTop: "5px"}} sx={{ m: 1, minWidth: 220 }} size="small">
<Form.Label htmlFor="inputPassword5" className='mt-3'>Select Spokes Type</Form.Label>
              
<Select
    labelId="demo-select-small"
    id="demo-select-small"
    value={productCycleWheelSpokes}
    label="Spokes"
    onChange={(e) => setproductCycleWheelSpokes(e.target.value)}
    placeholder="Select the Component"
>
            <MenuItem value={"Steel Spokes"}>Steel Spokes</MenuItem>
            <MenuItem value={"Carbon Fiber Spokes"}>Carbon Fiber Spokes</MenuItem>
            <MenuItem value={"Alloy Spokes"}>Alloy Spokes</MenuItem>
          
     
</Select>
</FormControl>
              <FormControl style={{marginTop: "5px"}} sx={{ m: 1, minWidth: 220 }} size="small">
<Form.Label htmlFor="inputPassword5" className='mt-3'>Select Stickers</Form.Label>
              
<Select
    labelId="demo-select-small"
    id="demo-select-small"
    value={productCycleStickersType}
    label="Stickers"
    onChange={(e) => setproductCycleStickersType(e.target.value)}
    placeholder="Select the Stcickers"
>
            <MenuItem value={"High-Quality Laser Water Stickers"}>High-Quality Laser Water Stickers</MenuItem>
            <MenuItem value={"HD Sticker"}>HD Sticker</MenuItem>
            <MenuItem value={"Minimal Sticker"}>Minimal Sticker</MenuItem>
          
     
</Select>
</FormControl>
              </div>
              
              <h4 style={{
  marginTop:15
}}>
  Packing and Assembly
</h4>
<div>
<FormControl style={{marginTop: "5px"}} sx={{ m: 1, minWidth: 320 }} size="small">
<Form.Label htmlFor="inputPassword5" className='mt-3'>Select Packing & Assembly</Form.Label>
              
<Select
    labelId="demo-select-small"
    id="demo-select-small"
    value={productCyclePackaging}
    label="Packing & Assembly"
    onChange={(e) => setproductCyclePackaging(e.target.value)}
    placeholder="Select the Packing & Assembly"
>
            <MenuItem value={"85% Assembled & Packed (Only 15 Min Assembly Required)"}>85% Assembled & Packed (Only 15 Min Assembly Required)</MenuItem>
            <MenuItem value={"85% Assembled & Packed (Only 25 Min Assembly Required)"}>85% Assembled & Packed (Only 25 Min Assembly Required)</MenuItem>
            <MenuItem value={"85% Assembled & Packed (Only 35 Min Assembly Required)"}>85% Assembled & Packed (Only 35 Min Assembly Required)</MenuItem>
          
     
</Select>
</FormControl>

</div>
<h4 style={{
  marginTop:15
}}>
    Components
</h4>
<div>
<FormControl style={{marginTop: "5px"}} sx={{ m: 1, minWidth: 200 }} size="small">
<Form.Label htmlFor="inputPassword5" className='mt-3'>Select Components</Form.Label>
              
<Select
    labelId="demo-select-small"
    id="demo-select-small"
    value={productCycleComponents}
    label="Product Components"
    onChange={(e) => setproductCycleComponents(e.target.value)}
    placeholder="Select the Packing & Assembly"
>
            <MenuItem value={"Taiwan Logan Hydraulic Disc Brake System"}>Taiwan Logan Hydraulic Disc Brake System</MenuItem>
            <MenuItem value={"Comfortable Sports MTB Saddle"}>Comfortable Sports MTB Saddle</MenuItem>
            <MenuItem value={"ED finish Alloy Double Butted Wide Handlebar"}>ED finish Alloy Double Butted Wide Handlebar</MenuItem>
          
     
</Select>
</FormControl>

</div>
                {!editButtonStatus ? 
                (
                <Button variant="primary" className='mt-2 mb-3' onClick={submitProduct}>Create Product</Button>

                ) : (
                <Button variant="primary" className='mt-2 mb-3' onClick={submitEditProduct}>Edit Product</Button>

                )
                }
      
              </div>
            </Tab.Pane>
            <Tab.Pane eventKey="third">
              <div>
                    <h4 className="pro-sidebar-title">Search </h4>
                              <div className="pro-sidebar-search mb-50 mt-25">
                                <form className="pro-sidebar-search-form" action="#">
                                  <input type="text" placeholder="Search here..." onChange={(e) => getAllSearchItems(e.target.value)}/>
                                  <button> 
                                    <i className="pe-7s-search" />
                                  </button>
                                </form>
                              </div>
              </div>
              <div>
                  {allProductsList && Object.entries(allProductsList).map((prod, index) => {
                    return(
                      <div style={{
                        backgroundColor:'#FFFFFF',
                        margin:50
                      }}>
                        <img
                        height={'500'}
                        width={'400'}
                        src={prod[1].mainImg}
                        />
                        {
                          prod[1].name
                        }
                        {
                          console.log('prod -> ', prod[1])
                        }
                        <br>
                        </br>
                        <Button style={{
                          backgroundColor:'#23B6FF',
                          margin:10
                        }} variant="contained" onClick={(e) => editProduct(e, prod[0])}>Edit</Button>
                        <Button style={{
                          backgroundColor:'#EC0000'
                        }} variant="contained" onClick={(e) => deleteProduct(e, prod[0])}>Delete</Button>
                      </div>  
                    )
                  })
                  }
              </div>
            </Tab.Pane>
          </Tab.Content>
        </Col>
      </Row>
    </Tab.Container>
        </div>
    </div>
  )
}

export default Products