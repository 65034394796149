import React, { useState, useEffect } from 'react';
import TextField from '@mui/material/TextField';
import { FormControl, TextareaAutosize, Button } from '@mui/material';

import firebase from 'firebase/compat/app'
import {
    getStorage,
    ref as sRef,
    uploadBytesResumable,
    uploadBytes,
    getDownloadURL 
} from "firebase/storage";
import { ref, runTransaction, getDatabase, set , onValue , get, onChildAdded, onChildChanged, onChildRemoved  } from 'firebase/database'
import { doc, onSnapshot, collection, query, where } from "firebase/firestore";
import { realDB } from '../../../../util/initFirebase';
import 'firebase/database'
import 'firebase/storage'
import { v4 as uuidv4 } from 'uuid';


function Blogs() {


  const [heading, setheading] = useState('');
  const [mainImg, setmainImg] = useState('');
  const [subHeading, setsubHeading] = useState('');
  const [subQuotes, setsubQuotes] = useState('');
  const [subImgOne, setsubImgOne] = useState('');
  const [subImgTwo, setsubImgTwo] = useState('');
  const [summary, setsummary] = useState('');



  const submitBlog = (e) => {
    e.preventDefault();
    const id = uuidv4();
    const db = getDatabase();
    set(ref(db, `blogs/${id}`), {
      id: id,
      heading: heading,
      mainImg: mainImg,
      subHeading: subHeading,
      subQuotes: subQuotes,
      subImgOne: subImgOne,
      subImgTwo: subImgTwo,
      summary: summary
    })
    .then( res => {
      setheading('');
      setmainImg('');
      setsubHeading('');
      setsubQuotes('');
      setsubImgOne('');
      setsubImgTwo('');
      setsummary('');
    })
    .catch(err => {
      console.log(err);
    })
  }

  const [manageBlogStatus, setmanageBlogStatus] = useState(false);
  const [getAllBlogs, setgetAllBlogs] = useState('');
  const getAllBlogsHere = () => {
    const db = getDatabase();
    const categories = ref(db, 'blogs/');
    onValue(categories, (snapshot) => {
        const data = snapshot.val();
        setgetAllBlogs(data);
      });
}


    useEffect(() => {
      getAllBlogsHere();
    }, [])
    
    const deleteTheBlogs = (e, id) => {
        e.preventDefault();
        const db = getDatabase();
        set(ref(db, `blogs/${id}`), {
         
        })
        .then(res => {
          
        })
    }



  if(!manageBlogStatus)
  return (
    <div>
        <h2 style={{
          margin:25
        }}>
            Blogs
        </h2>
        <div>   
          <div>
          <Button style={{
            marginLeft:25,
            marginBottom:25
          }} variant="contained" onClick={submitBlog}>Save and Publish</Button>
            </div> 
          <div>
          <Button style={{
            marginLeft:25,
            marginBottom:25
          }} variant="contained" onClick={() => setmanageBlogStatus(!manageBlogStatus)}>Manage Blog</Button>
            </div> 
        <div>
          <p style={{
            marginLeft:25,
            fontWeight:'700',
            fontSize:20
          }}>
            Paster the Main Image URL
          </p>
        <FormControl style={{
          marginLeft:25
        }} sx={{ m: 1, width: '150ch' }} variant="outlined" >
              <TextField value={mainImg} id="outlined-basic" label="Copy and Paste the URL" onChange={(e) => setmainImg(e.target.value) } variant="outlined" />
        </FormControl>
        </div>
        <div style={{
          marginTop:15
        }}>
          <p style={{
            marginLeft:25,
            fontWeight:'700',
            fontSize:20
          }}>
            Enter the Blog Heading
          </p>
        <FormControl style={{
          marginLeft:25
        }} sx={{ m: 1, width: '150ch' }} variant="outlined" >
              <TextField value={heading} id="outlined-basic" label="Enter Heading" variant="outlined" onChange={(e) => setheading(e.target.value) } />
        </FormControl>
        </div>
        <div style={{
          marginTop:15
        }}>
          <p style={{
            marginLeft:25,
            fontWeight:'700',
            fontSize:20
          }}>
            Enter the Blog Sub Headings
          </p>
        <FormControl style={{
          marginLeft:25
        }} sx={{ m: 1, width: '150ch' }} variant="outlined" >
               <TextareaAutosize
                type="textarea"
                minRows={5}
                value={subHeading}
                placeholder='Enter blog headings'
                onChange={(e) => setsubHeading(e.target.value)}
                />
        </FormControl>
        </div>
        <div style={{
          marginTop:15
        }}>
          <p style={{
            marginLeft:25,
            fontWeight:'700',
            fontSize:20
          }}>
            Enter the Blog Sub Quotes
          </p>
        <FormControl style={{
          marginLeft:25
        }} sx={{ m: 1, width: '150ch' }} variant="outlined" >
               <TextareaAutosize
                type="textarea"
                minRows={5}
                value={subQuotes}
                placeholder='Enter blog quotes'
                onChange={(e) => setsubQuotes(e.target.value)}
                />
        </FormControl>
        </div>
        <div>
          <p style={{
            marginLeft:25,
            fontWeight:'700',
            fontSize:20
          }}>
            Paster the Sub Image 1 URL
          </p>
        <FormControl style={{
          marginLeft:25
        }} sx={{ m: 1, width: '150ch' }} variant="outlined" >
              <TextField value={subImgOne} onChange={(e) => setsubImgOne(e.target.value)} id="outlined-basic" label="Copy and Paste the URL 1  " variant="outlined" />
        </FormControl>
        </div>
        <div>
          <p style={{
            marginLeft:25,
            fontWeight:'700',
            fontSize:20
          }}>
            Paster the Sub Image 2 URL
          </p>
        <FormControl style={{
          marginLeft:25
        }} sx={{ m: 1, width: '150ch' }} variant="outlined" >
              <TextField value={subImgTwo} onChange={(e) => setsubImgTwo(e.target.value) } id="outlined-basic" label="Copy and Paste the URL 2" variant="outlined" />
        </FormControl>
        </div>
        <div style={{
          marginTop:15
        }}>
          <p style={{
            marginLeft:25,
            fontWeight:'700',
            fontSize:20
          }}>
            Enter the Blog Details
          </p>
        <FormControl style={{
          marginLeft:25
        }} sx={{ m: 1, width: '150ch' }} variant="outlined" >
               <TextareaAutosize
                type="textarea"
                minRows={5}
                value={summary}
                placeholder='Enter blog Summary'
                onChange={(e) => setsummary(e.target.value) }
                />
        </FormControl>
        </div>
        </div>



    </div>
  )
  else{
    return(
      <div>
        <div>
          <Button style={{
            marginLeft:25,
            marginBottom:25
          }} variant="contained" onClick={() => setmanageBlogStatus(!manageBlogStatus)}>Write Blogs</Button>
            </div> 
            <p>
      Manage
    </p>
    <div>
            {getAllBlogs && Object.entries(getAllBlogs).map((blog, index) => {
              return(
                <div>
                    <div>
                      {
                        blog[1].heading
                      }
                    </div>
                    <Button variant="primary" onClick={(e) => deleteTheBlogs(e, blog[0])} className='mt-2'>
                        Delete
                    </Button>
                    
                </div>
              )
            })
            }
    </div>
      </div>
    
    )
  }
}

export default Blogs